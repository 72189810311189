<template>
  <div>
    <Section1 :keyWord="'La mejor solución para un crédito por tu coche en Sevilla'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4">La mejor solución para un crédito por tu coche en Sevilla</h2>

      <h3 class="naranja">¿Cómo empeñar tu coche en <strong>Sevilla</strong>?</h3>
      <p class="">En caso de que te preguntes si es posible empeñar tu coche en <strong>Sevilla</strong> <em>sin perder la titularidad del coche y sin pagar alquiler por usarlo</em>, 
      la respuesta es afirmativa. Si necesitas el dinero de manera inmediata, existe la posibilidad de que puedas empeñarlo para salir de apuros. 
      Esta es una opción que te ayudará a mantener la calma en una situación de emergencia. 
      Por ello, te queremos ofrecer toda la información al respecto sobre cómo es el procedimiento para hacer esto.</p>

      <p class="">Empeñar un vehículo siempre ha sido un plan B en situaciones complicadas cuando se necesita algo de dinero. 
      Esta <em>es una vía segura y rápida para poder resolver alguna situación que se escape de tus manos.</em></p>

      <p class="">El procedimiento para aprovechar esta nueva solución es muy sencillo. Con esto te queremos dejar claro que, 
      en caso de que se te presente un imprevisto o un gasto que no puedas afrontar, <em>podrás cubrir tu necesidad de manera inmediata gracias a este método.</em></p>

      <p class="">Además, queremos destacar que el procedimiento para poder ejecutar este plan B, es totalmente seguro en <strong>Sevilla</strong>, 
      por lo que no tendrás que preocuparte de nada. Al empeñar tu coche en <router-link to="/">Ibancar</router-link>, lograrás sacar lo mejor de esa situación tensa por la que estás pasando. 
      A continuación, te ofrecemos algunos de los requisitos que debes tener en cuenta en el momento que requieras recurrir a esta opción.</p>

      <h3 class="naranja">Requisitos para empeñar un coche sin entregarlo</h3>
      <p class="">Gracias a que <em>nuestro proceso de gestión es totalmente online</em>, podrá este tipo de préstamo realizando el formulario en la página web <router-link to="/" target="_blank">www.ibancar.com</router-link>
      y <em>de forma inmediata se le enviará una propuesta de préstamo según los datos de su vehículo.</em></p>
      <p class="">Lo mejor que podrás hacer de ahora en adelante, es olvidarte del largo proceso de papeleo, ya que <em>puedes hablar con nuestros gestores por email, teléfono o WhatApp.</em></p>
      <p class="">Algunos de los requisitos para realizar este proceso, son:</p>

      <ul>
        <li>Tener entre <em>21</em> y <em>72 años</em></li>
        <li>Tener un vehículo en propiedad <em>con menos de 14 años de antigüedad y libre de cargas</em></li>
        <li>Tener unos <em>ingresos  mínimos de 600€ al mes</em></li>
        <li>Si estás en <em>ASNEF o alguna lista de morosos, no te preocupes, te podemos ayudar.</em></li>
      </ul>

      <h3 class="naranja">Empeña tu coche en <strong>Sevilla</strong> y resuelve tus inconvenientes</h3>
      <p class="">Una vez que compruebas que tienes cada uno de los documentos en regla y que además cumples con cada requisito anteriormente mencionado, 
      podrás estar seguro de que tu vehículo será empeñado, <em>sin dejarlo en depósito, sin cambiar de titularidad y sin renting, 
      todo ello mediante la obtención de un préstamo que amortiza capital e intereses desde el primer día.</em></p>
      
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      <!--  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
      </div> 
<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Crédito por tu coche en Sevilla ▷ 100% online',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Consigue crédito por tu coche en Sevilla ¿Necesitas conseguir dinero rápido con tu coche? Entra aquí, te explicamos con lujos y detalles todo lo que necesitas saber para adquirir una financiación.'},
        {name:'keywords', content:'Dinero rápido con tu coche, Préstamo rápido y fácil, Conseguir dinero fácil.'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>